import styled from "styled-components"

// Components
import Plate from "./Plate";

// Models
import {StyledProps} from "../../models";

type StyledPlateProps = StyledProps

export default styled(Plate)`
  background-color: ${(props: StyledPlateProps) => props.theme.color.white};
  border-radius: 10px;
  box-shadow: ${(props: StyledPlateProps) => props.theme.shadow.balanced};
`
