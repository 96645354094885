import React from "react"
import styled from "styled-components"
import {withRouter, Router} from "next/router"

// Component
import AuthHeader from "./AuthHeader"

export type HeaderProps = {
    className: string
    title: string
    href: string
    buttonText: string
    router: Router
}

const StyledHeader = styled(AuthHeader)`
  align-items: center;
  background: transparent;
  box-sizing: border-box;
  display: flex;
  height: 50px;
  justify-content: flex-end;
  line-height: 50px;
  padding-right: 30px;
  position: absolute;
  right: 0;
  top: 30px;
  width: 100%;
  z-index: 1;
  
  .description {
    color: #7a8698;
    font-size: 16px;
  }
  
  .sign-up-link {
    display: block;
    line-height: 1;
    margin-left: 20px;
  }
  
  .sign-up-link-button {
    width: 135px;
  }
` as React.ComponentType<Omit<HeaderProps, "className">>

export default withRouter(StyledHeader)
