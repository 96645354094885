import styled from "styled-components"
import { compose } from "redux"

// Component
import AuthFormPlate from "./AuthFormPlate"

// Models
import { StyledProps, WithoutClassNameProps } from "../../../../models"

// HOCs
import withServerError, { WithServerErrorProps } from "../../hoc/withServerError"

export type AuthFormProps = {
  className?: string
  serverError?: string | null
  children?: Array<JSX.Element> | JSX.Element
  title?: string
  description?: string
  handleBack?(): void
} & WithServerErrorProps

const StyledPlate = styled(AuthFormPlate)`
    border-radius: 17px;
    padding: 10px 35px;
    width: 415px;
    max-width: 415px;
   
   
    position: relative;
  
  .title {
    font-size: 28px;
    font-weight: bold;
    text-align: center;
  }
  
  .desc {
    margin-bottom: 30px;
    text-align: center;
  }
  
  .back-icon {
    position: absolute; 
    left: 15px; 
    top: 15px;
  }
  
  .error {
    color: ${({ theme }) => theme.color.red};
    display: block;
    position: absolute;
    width: 100%;
    text-align: center;
    left: 0;
    bottom: 10px;
    line-height: 1;
  }
  
  & .has-error .ant-input {
    border-color: ${(props: StyledProps) => props.theme.color.red};
  }
  
  & .has-error .ant-form-explain {
    color: ${(props: StyledProps) => props.theme.color.red};
    font-size: 12px;
  }
  
  & .has-error .has-feedback .ant-form-item-children-icon {
    color: ${(props: StyledProps) => props.theme.color.red};
  }
  
  & .has-success .has-feedback .ant-form-item-children-icon {
    color: ${(props: StyledProps) => props.theme.color.green};
  }
  
  @media screen and (max-width: ${({ theme }) => theme.breakpoints.lg}) {
    padding: 20px;
  } 
` as WithoutClassNameProps<AuthFormProps>

export default compose(
  withServerError,
)(StyledPlate)
