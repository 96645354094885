import React from "react"

// Models
import {HeaderProps} from "./index"
import Button from "../../../../components/Button"
import Link from 'next/link'

/**
 * @param {HeaderProps} props
 * @returns {JSX.Element}
 */
export default function AuthHeader(props: HeaderProps) {
    const {className, title, href, buttonText} = props;

    return (
        <header className={className}>
            <span className="description">
                {title}
            </span>
            <Link href={href} as={href}>
                <a className="sign-up-link">
                    <Button className="sign-up-link-button">
                        {buttonText}
                    </Button>
                </a>
            </Link>
        </header>
    )
}
