import React, {useEffect} from "react"
import { ArrowLeftOutlined } from '@ant-design/icons';

// Components
import Plate from "../../../../components/Plate"

// Models
import {AuthFormProps} from "./index"

const AuthFormPlate = (props: AuthFormProps) => {
    const {
        className,
        children,
        serverError,
        title,
        description,
        handleBack,
        clearServerError,
    } = props;

    useEffect(() => {
        if (serverError !== null) {
            clearServerError()
        }

        return () => {
            if (serverError !== null) {
                clearServerError()
            }
        }
    }, [])

    return (
        <Plate className={className}>
            {typeof handleBack !== "undefined" && (
                <ArrowLeftOutlined
                    onClick={handleBack}
                    className="back-icon"
                />
            )}
            {typeof title !== "undefined" && (
                <h2 className="title">
                    {title}
                </h2>
            )}
            {typeof description !== "undefined" && (
                <p className="desc">
                    {description}
                </p>
            )}
            <>
                {children}
            </>
            {(typeof serverError !== "undefined" && serverError !== null) && (
                <span className="error">
                    {serverError}
                </span>
            )}
        </Plate>
    );
}

export default AuthFormPlate;