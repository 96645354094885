import React from "react"

// Components
import Head from "../Head"

/**
 * @param props
 * @returns {JSX.Element}
 */
export default function UnauthorizedPage(props) {
    const {title, description, className, children} = props;

    return (
        <div className={className}>
            <Head title={title} description={description}/>
            {children}
        </div>
    )
}
