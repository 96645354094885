import React from "react"
import {NextComponentType} from "next"
import {ReduxNextContext} from "./withRedux"

// Constants
import {Routes, TranslationNamespace} from "../constants"
import {AUTH_COOKIE_KEY} from "../modules/authorization/constants"

// Services
import CustomRouter from "../services/Router/Router"
import TokenStorageService from "../services/TokenStorageService/TokenStorageService"

export default function withAuthProtection<T extends object>(BaseComponent: NextComponentType<T>) {
    function Wrapper(props: any) {
        return <BaseComponent {...props}/>
    }

    Wrapper.getInitialProps = async function (context: ReduxNextContext) {
        const {req, res} = context;
        let token: string | null = null;

        if (typeof req !== "undefined") {
            token = req.cookies[AUTH_COOKIE_KEY];
        } else {
            token = TokenStorageService.getToken();
        }

        if (typeof token !== "undefined" && token !== null) {
            return CustomRouter.redirect(Routes.PATIENTS, res);
        }

        return {
            namespacesRequired: [TranslationNamespace.COMMON],
        }
    };

    return Wrapper
}