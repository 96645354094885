import React from "react"

interface PlateProps {
    className?: string
    children: Array<JSX.Element> | JSX.Element
}

/**
 * @param {PlateProps} props
 * @returns {JSX.Element}
 */
export default function Plate(props: PlateProps): JSX.Element {
    const {children, className = null} = props;

    return (
        <div className={className}>
            {children}
        </div>
    );
}
