import React, {useEffect} from "react"
import {Dispatch} from "redux"
import {connect} from "react-redux"

// Actions
import * as a from "../store/actions"

// Models
import {AppState} from "../../../store/models"

// Selectors
import {serverErrorStateSelector} from "../store/selectors"

export type WithServerErrorProps = {
    serverError: string | null
    clearServerError(): void
}

export default function withServerError(BaseComponent: any) {
    return connect(
        (state: AppState) => serverErrorStateSelector(state),
        (dispatch: Dispatch) => ({
            clearServerError: () => dispatch(a.clearServerError()),
        }),
    )((props: any) => {
        const {clearServerError, serverError} = props;

        useEffect(() => {
            return () => {
                if (serverError !== null) {
                    clearServerError()
                }
            }
        }, [])

        return (
            <BaseComponent {...props}/>
        )
    })
}
